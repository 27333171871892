.transaction-container {
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 110%;
    max-width: 1000px;
    min-width: 1000px;
    max-height: 360px;
    margin-bottom: 20px;
    overflow-x: auto;
    text-align: center; /* Centers inline-block children */
}

.transaction-container .transaction-h3 {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
    border: 2px solid #1a73e8; /* Add a solid blue border */
    display: inline-block; /* Ensures border wraps the text */
    padding: 8px 15px; /* Add space inside the border */
    border-radius: 5px; /* Slightly rounded corners */
}

.table-wrapper {
    overflow-x: auto;
}

.transaction-container table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    color: #333;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction-container th, .transaction-container td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
    word-wrap: break-word;
}

.transaction-container th {
    background-color: #1a73e8;
    color: white;
}

.transaction-container td {
    color: black;
}

.transaction-container td:nth-child(2) {
    max-width: 150px;
}

.transaction-container td:nth-child(3) {
    min-width: 120px;
}

.transaction-container tr:hover {
    background-color: #ddd;
}

.transaction-container select {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
    color: #333;
    font-size: 14px;
    appearance: none;
    outline: none;
    transition: border-color 0.3s ease;
    width: 100%;
}

.transaction-container select:focus {
    border-color: #1a73e8;
}

.transaction-container option {
    padding: 5px;
    background-color: white;
    color: black;
}

.transaction-container .transaction-row.pending {
    background-color: #fff9c4;
}

.transaction-container .transaction-row.successful {
    background-color: #c8e6c9;
}

.transaction-container .transaction-row.failed {
    background-color: #ffcdd2;
}

.transaction-row:hover {
    background-color: #ddd;
}

.transaction-container select {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"%3E%3Cpath fill="%23666" d="M2 5L0 3h4z" /%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 10px top 30%;
    background-size: 10px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .transaction-container table {
        max-width: 500px;
    }

    .transaction-container {
        max-width: 600px;
        min-width: 300px;
    }

    .transaction-container th, .transaction-container td {
        font-size: 12px;
        padding: 8px;
    }

    .transaction-container th {
        white-space: nowrap;
    }
}

@media (max-width: 600px) {
    .transaction-container table {
        max-width: 600px;
    }

    .transaction-container {
        max-width: 350px;
        min-width: 300px;
    }

    .transaction-container th, .transaction-container td {
        font-size: 12px;
        padding: 8px;
    }

    .transaction-container th {
        white-space: nowrap;
    }
}