.login-signUp {
    .container {
        display: flex;
        flex-direction: column;
        margin: 200px auto auto;
        width: 600px;
        background: #fff;
        padding-bottom: 30px;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 9px;
        width: 100%;
        margin-top: 30px;
    }

    .text {
        color: #1a73e8;
        font-size: 48px;
        font-weight: 700;
    }

    .underline {
        width: 61px;
        height: 6px;
        background: #1a73e8;
        border-radius: 9px;
    }

    .inputs {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .input {
        display: flex;
        align-items: center;
        margin: auto;
        width: 480px;
        height: 80px;
        background: #eaeaea;
        border-radius: 6px;
    }

    .input img, .user-outlined-png {
        margin: 0 30px;
    }

    .user-outlined-png {
        filter: invert(69%) sepia(3%) saturate(14%) hue-rotate(4deg) brightness(82%) contrast(86%);
    }

    .input input {
        height: 50px;
        background: transparent;
        border: none;
        outline: none;
        color: #797979;
        font-size: 19px;
    }

    .eye-icon {
        cursor: pointer;
        filter: invert(69%) sepia(3%) saturate(14%) hue-rotate(4deg) brightness(82%) contrast(86%);
        width: 35px;
        margin: 0 15px 0 auto !important;
    }

    .submit-container {
        display: flex;
        gap: 30px;
        margin: 30px auto;
    }

    .submit {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 59px;
        color: #fff;
        background: #1a73e8;
        border-radius: 50px;
        font-size: 19px;
        font-weight: 700;
        cursor: pointer;
    }

    .gray {
        background: #eaeaea;
        color: #676767;
    }

    .error {
        margin-top: 20px;
        color: red;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .login-signUp {
        .container {
            width: 90%;
            margin: 100px auto;
            padding: 20px;
        }

        .text {
            font-size: 36px;
        }

        .underline {
            width: 50px;
            height: 4px;
        }

        .input {
            height: 50px;
            width: 100%;
        }

        .input img {
            margin: 0 15px;
        }

        .input input {
            font-size: 16px;
        }

        .submit-container {
            flex-direction: column;
            gap: 15px;
            width: 100%;
        }

        .submit {
            width: 100%;
            height: 45px;
            font-size: 14px;
        }
        .input img, .user-outlined-png {
            margin: 0 15px;
        }

        .user-outlined-png {
            filter: invert(69%) sepia(3%) saturate(14%) hue-rotate(4deg) brightness(82%) contrast(86%);
        }
    }
}