.cardForm {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    border: 1px solid #ddd;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 20px auto;
}

.cardForm .formGroup {
    margin-bottom: 20px;
}

.cardForm .label {
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
    font-size: 14px;
}

.cardForm .custom-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #fafafa;
    transition: all 0.3s ease;
}

.cardForm .custom-input:focus {
    border-color: #4096ff;
    outline: none;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(64, 150, 255, 0.5);
}

.cardForm .submitButton {
    background-color: #4096ff;
    color: white;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
}

.cardForm .submitButton:hover {
    background-color: #307de1;
}

.cardForm .submitButton:disabled {
    background-color: #d3d3d3;
    color: #666;
    cursor: not-allowed;
}

