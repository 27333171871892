.notifications-dropdown {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0;
    max-width: 600px;
    max-height: 600px;
}

.ant-dropdown {
    max-width: 600px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
}

.notification-dropdown .ant-dropdown-menu {
    max-height: 600px; /* Limits the dropdown height */
    overflow-y: auto; /* Adds scroll when there are many notifications */
}

.ant-dropdown-menu {
    max-width: 100%;
    max-height: 600px;
}

.notification-item .ant-list-item-meta-title,
.notification-item .ant-list-item-meta-description {
    max-width: 600px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.notification-item {
    cursor: pointer;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
}

.notification-item:hover {
    background-color: #f0f2f5;
}

.notification-item.unread {
    background-color: lightblue;
}

.notification-item .ant-list-item-meta-title {
    font-weight: bold;
    color: #1a73e8;
}

.notification-item .ant-list-item-meta-description {
    font-size: 0.9rem;
    color: #666;
}

.mark-all-container {
    text-align: center;
    border-top: 1px solid #d9d9d9;
    padding: 10px;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 1; /* Ensure it stays on top of other content */
}

.mark-all-btn {
    color: #1a73e8;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    width: 100%;
}

.mark-all-btn:hover {
    color: #0f5bb5;
    background: transparent none;
    text-decoration: underline;
}

.no-notifications {
    text-align: center;
    margin: 10px 0;
    color: #999;
    font-size: 0.95rem;
}

.notifications-badge {
    cursor: pointer;
    display: inline-block;
}

.sticky-mark-all {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 8px;
    text-align: center;
    border-top: 1px solid #f0f0f0;
}

.notifications-badge .ant-badge-count {
    background-color: #f5222d;
    color: white;
    font-size: 0.8rem;
    font-weight: bold;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.notifications-badge .anticon {
    font-size: 20px;
    color: white;
}

@media (max-width: 600px) {
    .notifications-dropdown {
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 0;
        max-width: 340px;
        max-height: 600px;
    }

    .ant-dropdown-menu {
        max-width: 340px;
        max-height: 600px;
    }
}