.CurrencyConverter .ant-select {
    font-size: 16px;
    width: 100px;
}

.CurrencyConverter .ant-input {
    font-size: 16px;
    padding: 10px;
    height: 32px;
    width: 150px;
}

.CurrencyConverter .result p {
    font-size: 24px;
    margin-top: 10px;
    color: #333;
}

.CurrencyConverter {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;

    .heading {
        font-family: 'Pacifico', cursive;
        font-size: 40px;
        color: #1a73e8;
        margin-bottom: 20px;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 600px;
        padding: 20px;
        background: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 20px;
    }

    .left, .middle, .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
    }

    h3 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #1a73e8;
    }

    input {
        width: 100%;
        font-size: 16px;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .switch {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        background-color: lightblue;
        border-radius: 50%;
        cursor: pointer;
    }

    .result {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 600px;
        text-align: center;
    }

    button {
        font-size: 18px;
        padding: 8px 20px;
        margin: 10px 0;
        background-color: #1a73e8;
        border: 2px solid #1a73e8;
        border-radius: 5px;
        cursor: pointer;
        color: #1a73e8;
        transition: background-color 0.3s;
    }

    button:hover {
        background-color: #1a73e8;
        color: white;
    }

    p {
        font-size: 24px;
        margin-top: 10px;
        color: #333;
    }

    .dropdown {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border-radius: 5px;
    }

}

@media (max-width: 768px) {
    .CurrencyConverter {
        padding: 10px;

        .heading {
            font-size: 28px;
        }

        .container {
            width: 100%;
            max-width: 100%;
            flex-direction: column;
            align-items: center;
            gap: 15px;
        }

        .container > div {
            margin-bottom: 10px;
        }

        button {
            width: 100%;
        }

        .result,
        .dropdown,
        input {
            width: 100%;
        }

        .header {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            background-color: #1a73e8;
            color: white;
        }

        .header a {
            color: white;
            text-decoration: none;
            margin: 0 10px;
        }
    }
}
