.table-container {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.table-responsive {
    overflow-x: auto;
    width: 100%;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

h1 {
    color: #666;
    margin: 0;
}

.search-input {
    width: 150px;
}

.search-input:focus {
    border-color: #267de3;
}

.add-user-btn, .send-notification-btn {
    background-color: #1a73e8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.add-user-btn:hover, send-notification-btn:hover {
    background-color: #267de3;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    table-layout: auto;
}

.table-container th, .table-container td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

th:nth-child(2), td:nth-child(2) {
    max-width: 150px;
}

th:nth-child(3), td:nth-child(3) {
    max-width: 200px;
}

th:nth-child(5), td:nth-child(5) {
    width: 180px;
}

td button {
    margin: 0 5px;
}

tr:hover {
    background-color: #f9f9f9;
}

button {
    border: none;
    background: none;
    cursor: pointer;
}

.edit-btn {
    color: #007bff;
    font-size: 16px;
}

.delete-btn {
    color: #dc3545;
    font-size: 16px;
}

.create-card-btn {
    color: #28a745;
    font-size: 16px;
}

.create-card-btn svg {
    width: 20px;
    height: 20px;
}

button i {
    pointer-events: none;
}

.edit-btn:hover {
    color: #0056b3;
}

.delete-btn:hover {
    color: #c82333;
}

.create-card-btn:hover {
    color: #218838;
}

.disabled-row {
    cursor: not-allowed;
    background-color: #f9f9f9;
}

.enabled-row {
    cursor: pointer;
}

.disabled-row:hover {
    background-color: #f9f9f9;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.header-actions {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

.user-table th,
.user-table td {
    text-align: center;
    vertical-align: middle;
}

.ant-tooltip-inner {
    text-align: center;
}

@media (max-width: 768px) {

    .user-table th,
    .user-table td {
        text-align: center;
    }

    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }

    h1 {
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    .header-actions {
        width: 100%;
        flex-direction: column;
    }

    .search-input {
        width: 100%;
        margin-bottom: 10px;
    }

    .add-user-btn, .send-notification-btn {
        width: 100%;
        padding: 15px;
        font-size: 14px;
    }
}