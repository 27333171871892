/* Base styles */
.ticker-container {
    width: 100vw;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: #f4f4f4;
    padding: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.ticker-text {
    display: inline-block;
    white-space: nowrap;
    animation: ticker-scroll 15s linear infinite;
    font-size: 1.2rem;
    color: #333;
    will-change: transform;
}

@keyframes ticker-scroll {
    0% {
        transform: translateX(100vw); /* Start fully off-screen to the right */
    }
    100% {
        transform: translateX(-100%); /* End fully off-screen to the left */
    }
}

/* Mobile devices */
@media (max-width: 768px) {
    .ticker-text {
        font-size: 1rem;
    }

    .ticker-container {
        padding: 8px 0;
    }
}
