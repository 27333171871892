.user-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.header {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.main-content {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.left-section {
    flex: 1;
    max-width: 350px;
}

.transaction-component, .money-transfer-component {
    flex: 1;
}

@media (max-width: 768px) {
    .main-content {
        flex-direction: column;
    }

    .left-section, .right-section {
        width: 100%;
    }
}
