* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 1rem;
    background-color: #1a73e8;
}

.logo {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    gap: 3px;
}

.nav-links {
    list-style: none;
    display: flex;
}



.nav-links a {
    text-decoration: none;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background 0.3s;
}

.nav-links a:hover {
    background-color: #267de3;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.hamburger .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px;
    transition: all 0.3s;
}

.child-content {
    padding-top: 2rem;
    background: linear-gradient(90deg, #1244ba, #1980cf 61.37%, #177cce 71.37%);
    min-height: calc(100vh - 60px);
}

.child-content p {
    font-size: 1.2rem;
    color: #333;
}

.lili {
    font-size:15px;
    padding:0;
}
