.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main_container{
    text-align: center;
    
  
}

.header_container{
    background: linear-gradient(90deg, #1244ba, #1980cf 61.37%, #177cce 71.37%);
    padding: 10px 40px;
    justify-content:space-between;
    align-items: center;

}

.header_nav{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.navInner{
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;

}

.ddrop{
    display: flex;;
}

.header_inner{
    position: relative;
    width:100%;
    justify-content: space-between;
    align-items: flex-end;
    gap: 48px;

}


ul{
    display: flex;
    padding: 0;
}

li{
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    padding: 10px 20px;
    list-style: none;
}

li:hover{
    font-size: 20px;
}

.logo{
    flex-shrink: 0;
    pointer-events: initial;


}

.logo img {
    max-height: 100%;
    height: auto;
}

.burger_menu {
    display: none;
    cursor: pointer;
}

.burger_menu i {
    font-size: 24px;
    color: #fff;
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .navInner {
        display: none !important;
    }

    .burger_menu {
        display: flex !important;
        align-items:center !important ;
    }

    .navInner.active {
        display: flex !important;
        flex-direction: column !important;
        position: absolute !important;
        top: 60px !important;
        right: 0 !important;
        background: #1244ba !important;
        width: 100% !important;
        text-align: center !important;
        z-index: 10 !important;
    }

    .navItems,
    .authItems {
        display: flex !important;
        flex-direction: column !important;
    }

    .navItems li,
    .authItems li {
        padding: 15px 0 !important;
    }

    .ddrop{
        text-align: left !important;
        flex-direction: column !important;
    }

}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
    .banger{
        display: none !important;
    }

    .basec{
        width:45% !important;
    }

    .nigga{
        width: 45% !important;
    }

    .maiincon{
        justify-content: space-around !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .maiincon{
        flex-direction: column !important;
    }

    .basec{
        width:60% !important;
    }

    .nigga{
        width: 60% !important;
        margin: 0 !important;
    }

    .banger{
        display: none !important;
    }

    .maiincon{
        align-items: center;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .maiincon{
        flex-direction: column !important;
    }

    .basec{
        width:60% !important;
    }

    .nigga{
        width: 60% !important;
        margin: 0 !important;
    }

    .banger{
        display: none !important;
    }

    .maiincon{
        align-items: center !important;
    }

    .profilecon{
        align-items: center !important;
        flex-direction: column !important;
    }

    .profileform{
        width: 70% !important;
    }

    .profileinfo{
        width: 70% !important;
        margin-top: 50px !important;
    }

}


@media only screen and (min-width: 481px) and (max-width: 767px) {
    .maiincon{
        flex-direction: column !important;
    }

    .basec{
        width:70% !important;
    }

    .nigga{
        width: 70% !important;
        margin: 0 !important;
    }

    .banger{
        display: none !important;
    }

    .maiincon{
        align-items: center;
    }

}

@media only screen and (max-width: 480px) {
    .maiincon{
        flex-direction: column !important;
    }

    .basec{
        width:85% !important;
    }

    .nigga{
        width: 85% !important;
        margin: 0 !important;
    }

    .banger{
        display: none !important;
    }

    .maiincon{
        align-items: center;
    }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .ahu_container{
        flex-direction: column;
    }

    .zalup img{
        display: none;
    }

    .inst_pay{

        width:100% !important;
    }

    .media{
        margin: 0px 30px;
        width: 100% !important;
    }

    .phone{

        width: 100% !important;
    }

    .card_acc{

        width: 100% !important;
    }

    .dickpic img{
        padding: 0 !important;
    }

    .second{
        padding-left:30px !important;
        padding-right:30px !important;
    }

    .get{
        font-size: 14px !important;
        
    }

}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .ahu_container{
        flex-direction: column;
    }

    .zalup img{
        display: none;
    }

    .inst_pay{

        width:100% !important;
    }

    .media{

        width: 100% !important;
    }

    .phone{

        width: 100% !important;
    }

    .card_acc{

        width: 100% !important;
    }

    .fstmenu h1{
        font-size: 22px !important;
    }

    .sup {
        padding: 0px 30px !important;
    }

    .scdmenu li{
        font-size: 12px !important;
    }

    .scdmenu h4{
        font-size: 12px !important;
    }

    .dickpic img{
        padding: 0 ,50px !important;
        max-width: 100px;
    }

    .second{
        padding-left:0 !important;
        padding-right:0 !important;
    }

    .get{
        font-size: 12px !important;
        
    }
  
}

@media only screen and (max-width: 480px) {
    .ahu_container{
        flex-direction: column !important;
    }

    .inst_pay {
        width: 100% !important;
    }

    .media{
        width: 100% !important;
    }

    .phone{
        width: 100% !important;
    }

    .card_acc{
        width: 100% !important;
    }

    .sup{
        padding:0 10px !important;
    }

    .zalup img{
        display: none !important;
    }

    .navInner {
        display: none !important;
    }

    .burger_menu {
        display: flex !important;
        align-items:center !important ;
    }

    .navInner.active {
        display: flex !important;
        flex-direction: column !important;
        position: absolute !important;
        top: 60px !important;
        right: 0 !important;
        background: #1244ba !important;
        width: 100% !important;
        text-align: center !important;
        z-index: 10 !important;
    }

    .navItems,
    .authItems {
        display: flex !important;
        flex-direction: column !important;
    }

    .navItems li,
    .authItems li {
        padding: 15px 0 !important;
    }

    .ddrop{
        text-align: left !important;
        flex-direction: column !important;
    }

    .fstmenu h1{
        font-size: 15px !important;
    }

    .scdmenu h4{
        font-size: 13px !important;
    }

    .scdmenu li{
        font-size: 12px !important;
    }

    .dickpic img{
        width: 70px !important;
    }

    .second{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .gi{
        width:100% !important;
    }

    .hover{
        font-size: 14px !important;
    }

    .hover2{
        font-size: 14px !important;
    }

    .sup h1{
        font-size: 45px !important;
    }

    .get{
        font-size: 10px !important;
      
    }

}


.navInner{
    flex: 3;
    display: flex;
}

.hover{
    margin: 10px;
    color: black;
    background-color: #6ee7b7;
    border-radius: 25px;
}

.hover2{
    margin: 10px;
    border: 2px solid #6ee7b7;
    margin-left: 30px;
    background-color: #282c34;
    border-radius: 25px;
}

.hover:hover{
    transition: 500ms;
    background-color: #322a42;
    color:white;
}

.hover2:hover{
    transition: 500ms;
    background-color: #ffffff;
    color:black;
}

.zalup img{
    width: 100%;
    height: 110%;
}

.first{

    display: flex;
    flex-direction: row;
    background: linear-gradient(90deg, #1244ba, #1980cf 61.37%, #177cce 71.37%);
}

.sup{
    padding: 0 150px;
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    justify-content: center;
}

.sup h1{
    color: #fff;
    font-weight: 400;
    font-size: 54px;
}

.sup p{
    color: #fff;
    font-weight: 500;
    font-size: 18px;
}

.inf{
    font-family: Tiempos Headline;
    color: #00c9fe;
    font-style: italic;
}

.second{
    padding-left: 100px;
    padding-right: 100px;
    background: linear-gradient(to bottom, rgb(6 95 154) 80%, transparent 100%);
    padding-top: 70px;
    padding-bottom: 225px;
}


/* .dickpic img{
    padding: 20px;
} */

.ver h1{
    color: white;
    font-weight: 400;
    font-size: 48px;
    font-family: DM Sans, Arial, Helvetica, sans-serif;
}

.ver p{
    color:white;
    font-weight: 500;
    font-size: 21px;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

.ahu_container{
    display: flex;
}

.inst_pay{
    width:50%;
}

.head{
    margin: 0 30px;
    align-items: center;
    display: flex;
}

.head h3{
    color: #1470cc;
    font-size: 1rem;
}

.fed{
    margin: 0 30px;
    display:flex;
    flex-direction: column;
}

.fed p{
    width: 80%;
    text-align: start;
    font-weight: 500;
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

.fed h1{
    text-align: start;
    font-weight: 400;
    font-size: 48px;
    font-family: DM Sans, Arial, Helvetica, sans-serif;
}

.mini_grid{

    margin: 0 30px;
    display: flex;
    flex-wrap: wrap;
}



.any{
    margin: 0 30px;
}

.gi{
    gap:8px;
    margin-top:20px;
    align-items: center;
    display: flex;
    width: 40%;
}

.gi p{
    font-weight: 700;
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

.media{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    width:50%;
}


.media_img{
    max-width: 650px;
    max-height: 350px;
}

.before{
    transform: translateY(-200px);
    align-self:center;
    padding:20px;
    width: 70%;
    border: 1px blue solid;
    border-radius: 10px;
    background: white;
}

.jir{
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
}

.huyase{
    align-items: center;
    display: flex;
    flex-direction: row;
}

.brick{
    margin-left: 20px;
}

.mini{
    text-align: left;
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

.micro{
    font-weight: 500;
    font-size: .625rem;
    font-family: Inter, Arial, Helvetica, sans-serif;
}


.quote_img{
    border-radius: 6px;
    width:32px;
    height:32px;
}


.card_acc{
    width:50%;
}


.phone{
    flex-direction: column;
    display: flex;
    align-items: center;
    width:50%;
}

.phone_img{
    width: 80%;
}



.last_container{
    padding:48px 18px;
}

.readyto{
    border: .75px solid #2155d9;
    border-radius: 12px;
    width:80%;
    background: linear-gradient(104deg, #003f8d 1.17%, #1b6fd6 53.33%, #00c9fe), linear-gradient(90deg, #0009, #0000 69.38%), linear-gradient(0deg, #0000004d 0% 100%), #d3d3d3 -316.467px -555.976px / 143.667% 327.836% no-repeat;
    padding:82px 32px;
    margin: 0 auto;
}

.ww{
    color:white;
    font-weight: 400;
    font-size: 48px;
    font-family: DM Sans, Arial, Helvetica, sans-serif;
}

.whi{
    color: white;
    font-weight: 400;
    font-size: 54px;
    font-family: Tiempos Headline;
    font-style: italic;
}

.idor{
    margin: 0 auto;
    align-self: center;
    width:70%;
    color:white;
    font-weight: 500;
    font-size: 18px;
    font-family: Inter, Arial, Helvetica, sans-serif;
}

.get{
    border-radius: 20px;
    margin:20px auto;
    width: 50% !important;
    padding:10px;
    color:black;
    background: white;
    font-size: 16px;
    font-weight: 600;
}

.get:hover{
    color:white;
    transition: 500ms;
    background-color: #322a42;
}

footer{
    justify-content: center;
    display:flex;
    
    background:#f5f8fa;
}

.fstmenu{

    width:30%;
}

.fstmenu h1{
    text-align: start;
    font-weight: 400;
    font-size: 42px;
    font-family: Tiempos Headline;
    font-style: italic;
   

}

.fstmenu p{
    text-align: left;
    font-weight: 400;
    font-size: .875rem;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-style: normal;
}

.fstmenu li{
    margin-top: 30px;
}

.scdmenu{
    
    flex-direction: column;
    display: flex;
    
}

.scdmenu h4{
    padding: 0px 20px;
    text-align: start;
}

.scdmenu ul{
    display: flex;
    flex-direction: column;
    text-align: start;

}

.scdmenu li{
    color: black;
}


.maiincon{
    background: linear-gradient(90deg, #1244ba, #1980cf 61.37%, #2288db 71.37%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.heeeed{
    color: white;
}

.nigga{
    flex-direction: column;
    display: flex;
    justify-content: start;
    margin-left: 3%;
    width:35%
}

.banger{
    margin-left: 3%;
    width: 20%;

    display: flex;
    align-items: center;
    justify-content: center; /* Center the content horizontally and vertically */
    overflow: hidden; /* Hide any overflow content */
}

.bangerima{
    width: 100%;
    height: 100%;
}

.bangerima img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.tranbox{
    background: rgb(42,32,205);
    background: linear-gradient(266deg, rgba(42,32,205,1) 0%, rgba(33,175,255,1) 51%, rgba(10,45,88,1) 100%);
    padding: 5px;
    border-radius: 10px;
    border:1px darkblue, solid;
}

.intran{
    min-height: 250px;
    background: #ffffff;
    border-radius: 10px;
    border: #00c9fe, 1px, solid;
}

.transbox{
    margin-top: 35px;
    background: rgb(42,32,205);
    background: linear-gradient(266deg, rgba(42,32,205,1) 0%, rgba(33,175,255,1) 51%, rgba(10,45,88,1) 100%);
    padding: 5px;
    border-radius: 10px;
    border:1px darkblue, solid;
}

.intrans{
    margin: 0px 50px;
    text-align: left;
    padding: 5px;
    display:flex;
    flex-direction: column;
}

.intrans input{
    height: 30px;
    width:100%;
    border-radius: 10px;
}

.intrans p{
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 5px;
}

.basec{
    width: 30%;
    flex-direction: column;
    display: flex;
}

.cardContainer {
    width: 100%;

    /* max-height: 326px; */
    /* max-width: 400px; */
    position: relative;
    padding-top: 64.25%;

}

.cardImage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    background-size: cover;
    /* background-position: center; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.bankont {
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
}

.cardDetails{
    width: 100%;
    padding: 10%;
    box-sizing: border-box;
    /* margin: 7% 0px; */
}

.firstrow, .secondrow, .thirdrow {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* .firstrow{
  margin-bottom: 10%;
} */

.secondrow{
    margin-top: 50%;
}



/* .nameca{
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(0, 140, 255);
}

.exp{
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
} */

.logocard{
    width:15%;
}

.thirdrow{
    margin-bottom: 10%;
}

.vilog{
    width: 120px;
}

.cvv, .exp, .cardnum {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(255, 255, 255);
}

.nameca{
    color: rgb(99, 199, 238);
    font-weight: bold
;
}

@media (max-width:600px) {
    .cardContainer{
        width: 100%;
    };

    .cvv, .nameca, .exp, .cardnum {
        font-size: 0.8rem;
    }

    .logocard, .vilog {
        width: 10%;
    }
}



/* .cardnum{
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
} */

/* .cvv{
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
} */



.trncon{
    background:linear-gradient(180deg, #1244ba, #1980cf 61.37%, #177cce 71.37%);
    border-radius: 25px;
    margin: 30px 8px;
    /* height: 200px; */
    border: 1px solid blue;
}

.bala{
    padding: 0% 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.balap{
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
}

.balapa{
    font-weight: bold;
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
}

.twocol{
    padding: 0% 5%;
    flex-direction: row;
    display: flex;
    margin-bottom: 3%;
}

.smert{
    border: 1px solid white;
    border-radius: 15px;
    padding: 0px 10px;
}

.smertnah{
    border-radius: 15px;
    border: 1px solid white;
    padding: 0px 10px;
    margin-left: 10%;
}

.lil{
    text-align: start;
    color: white;
}

.chicha{
    text-align: start;
    color:white;
    font-weight: bold;
}

.savings{
    padding: 0% 5%;
    display: flex;
    flex-direction: row;
    justify-content: start;
}



.walle{
    width:30px;
    height:30px;
}

.savings{
    display: flex;

}

.sometext{
    margin-left: 2%;
}


.somep{
    color:white;
    margin: 0;
}

.somebp{
    color: white;
    font-weight: bold;
    text-align: left;
}

.addmo{
    padding: 20px;
    display:flex;
    justify-content:center;

}

.adolf{
    border-radius: 10px;
    color: #ffffff;
    font-weight: bold;
    background: rgb(42,32,205);
    background: linear-gradient(17deg, rgba(42,32,205,1) 0%, rgba(33,175,255,1) 41%, rgba(0,112,255,1) 100%);
    padding:10px 20px;
    border: none;

}

.logincon{
    display: flex;
    flex-direction: column;
}

.concon{
    margin: 5%;
    justify-content: center;
    display: flex;
    text-align: left;
}

.form-label{
    font-weight: bold;
}

.bitton{
    border: none;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    background: #187dcf;
    padding: 7px 20px;
    margin: 20px 0px;
}

.zelepuha{
    padding: 5px;
    height: 25px;
    border: 1px solid #6ee7b7;
    border-radius: 8px;
}

.newcus{
    font-weight: bold;
}

.profilecon{
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    margin:50px;
}

.profileform{
    padding:20px;

    border-radius: 10px;
    border: 1px green solid;
}

.profileinfo{
    padding:20px;
    width:60%;
    border-radius: 10px;
    border: 1px green solid;
}

